import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useRouter } from "next/router";

import {
    SearchIcon,
    LocationMarkerIcon,
    ClockIcon,
    HomeIcon,
} from "@heroicons/react/outline";

import { Menu, Transition } from "@headlessui/react";

import { getMostRecentSearchCity, setMostRecentSearchCity } from "../local/localStorage";
import { usePopper } from "@/ui/hooks";

const MenuPortal = React.forwardRef(({ children, style, className }, ref) => {
    if (typeof document === "undefined") return <div></div>;

    const el = useRef(document.createElement("div"));

    useEffect(() => {
        const modalRoot = document.getElementById("body-for-popper");
        modalRoot.appendChild(el.current);
        const elCurrent = el.current;
        return () => {
            modalRoot.removeChild(elCurrent);
        };
    }, []);

    return ReactDOM.createPortal(
        <div ref={ref} style={style} className={className}>
            {children}
        </div>,
        el.current
    );
});

const HomeSearch = ({
    enableSearchButton = true,
    inputClassName = "sm:w-[500px]",
    value = "",
}) => {
    const router = useRouter();
    const buttonRef = useRef();
    let [trigger, container] = usePopper(
        {
            placement: "bottom-start",
            strategy: "fixed",
            modifiers: [{ name: "offset", options: { offset: [-500, 0] } }],
        },
        buttonRef
    );
    const [search, setSearch] = useState(value);
    //const [results, setResults] = useState(["nothing"]);
    const [cities, setCities] = useState([]);
    const [communities, setCommunities] = useState({});
    const [openDropdown, setOpenDropdown] = useState(false);
    const [mostRecent, setMostRecent] = useState("");
    const timeoutDuration = 200;
    let timeout;

    useEffect(() => {
        const city = getMostRecentSearchCity();
        if (city) {
            setMostRecent(city);
        }
    }, []);

    useEffect(() => {
        setSearch(value);
    }, [value]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(`/api/search/${search.replaceAll(" ", "+")}`);

            const data = await res.json();

            console.log(data);

            const _cities = {};

            if (data && data.data && data.data.addresss && data.data.addresss.length > 0) {
                data.data.addresss.forEach((a) => {
                    const key = a.city + ", " + a.state;
                    _cities[key] = { city: a.city, state: a.state };
                });
            }
            setCities(_cities);

            if (data && data.data && data.data.communitys) {
                setCommunities(data.data.communitys);
            }
        };

        if (search && search.length > 0) {
            fetchData();
        } else {
            setCities({});
            setCommunities([]);
        }
    }, [search]);

    const onFocus = () => {
        clearTimeout(timeout);

        setOpenDropdown(true);
    };
    const onBlur = () => {
        timeout = setTimeout(() => {
            setSearch("");
            setOpenDropdown(false);
        }, timeoutDuration);
    };

    const handleEnter = () => {
        if (search) {
            // first city?
            console.log("cities", cities);
            if (Object.keys(cities).length > 0) {
                let city = Object.keys(cities)[0];
                router.push("/" + city.replaceAll(" ", "-").replaceAll(",", ""));
            }
        } else if (mostRecent) {
            router.push("/" + mostRecent.replaceAll(" ", "-").replaceAll(",", ""));
        }
    };

    const handleArrowDown = () => {
        console.log("arrow down", buttonRef.current, buttonRef);
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    };

    return (
        <Menu as="div" className="relative block text-left">
            {({ open }) => (
                <>
                    <div>
                        <form
                            autoComplete="off"
                            className="mt-4 flex items-center"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <input
                                className={
                                    "p-2 text-lg text-gray-900 rounded-l rounded-r-none w-full " +
                                    inputClassName
                                }
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                name="-purple-door-search"
                                placeholder="City or Name of Community/Agency"
                                autoComplete="off"
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onKeyUp={(e) => {
                                    console.log(e.key);
                                    if (e.key === "Enter") {
                                        handleEnter();
                                    } else if (e.key === "ArrowDown") {
                                        handleArrowDown();
                                    }
                                }}
                            />
                            <Menu.Button
                                ref={buttonRef}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                className={`flex items-center bg-primary-main text-white border 
                                            border-primary-main rounded-r 
                                            hover:bg-primary-med hover:border-primary-med`}
                            >
                                <SearchIcon className="w-12 h-11 p-2" />
                                {enableSearchButton ? (
                                    <span className="hidden sm:inline sm:ml-2 sm:mr-4 text-xl">
                                        Search
                                    </span>
                                ) : null}
                            </Menu.Button>
                        </form>
                    </div>

                    <Transition
                        show={openDropdown}
                        enter="transition ease-out duration-300"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <MenuPortal
                            ref={container}
                            className="border-none"
                            style={{ zIndex: 1000 }}
                            className="w-full sm:w-[633.75px]"
                        >
                            <Menu.Items
                                static
                                onFocus={onFocus}
                                onBlur={onBlur}
                                className={`absolute z-10 left-0 w-full sm:w-[633.75px] mt-[2px] origin-top-right bg-gray-50 divide-y divide-gray-200
                                 rounded-b rounded-bt overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5
                                 focus:outline-none`}
                            >
                                {mostRecent ? (
                                    <div className="pt-4">
                                        <div className="flex items-center">
                                            <ClockIcon className="mx-4 w-4 h-4 text-primary-500" />
                                            <h3 className="text-md text-primary-500 font-bold">
                                                MOST RECENT SEARCH
                                            </h3>
                                        </div>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    className={`${
                                                        active
                                                            ? "bg-primary-100 text-gray-900"
                                                            : "text-gray-900"
                                                    } group flex items-center w-full px-12 py-2 text-sm`}
                                                    href={
                                                        "/" +
                                                        mostRecent
                                                            .replaceAll(" ", "-")
                                                            .replaceAll(",", "")
                                                    }
                                                >
                                                    {mostRecent}
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </div>
                                ) : null}

                                {Object.keys(cities).length > 0 ? (
                                    <div className="pt-4">
                                        <div className="flex items-center">
                                            <LocationMarkerIcon className="mx-4 w-4 h-4 text-primary-500" />
                                            <h3 className="text-md text-primary-500 font-bold">
                                                CITIES
                                            </h3>
                                        </div>
                                        {Object.keys(cities)
                                            .filter((_, i) => i < 10 - communities.length)
                                            .map((city) => (
                                                <Menu.Item key={city}>
                                                    {({ active }) => (
                                                        <a
                                                            className={`${
                                                                active
                                                                    ? "bg-primary-100 text-gray-900"
                                                                    : "text-gray-900"
                                                            } group flex items-center w-full px-12 py-2 text-sm`}
                                                            href={
                                                                "/" +
                                                                city
                                                                    .replaceAll(" ", "-")
                                                                    .replaceAll(",", "")
                                                            }
                                                            onClick={() => {
                                                                setMostRecentSearchCity(
                                                                    city
                                                                );
                                                            }}
                                                        >
                                                            {city}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                    </div>
                                ) : null}

                                {communities.length > 0 ? (
                                    <div className="pt-4">
                                        <div className="flex items-center">
                                            <HomeIcon className="mx-4 w-4 h-4 text-primary-500" />
                                            <h3 className="text-md text-primary-500 font-bold">
                                                COMMUNITIES
                                            </h3>
                                        </div>
                                        {communities
                                            .filter((_, i) => i < 5)
                                            .map((community) => (
                                                <Menu.Item key={community.nameId}>
                                                    {({ active }) => (
                                                        <a
                                                            className={`${
                                                                active
                                                                    ? "bg-primary-100 text-gray-900"
                                                                    : "text-gray-900"
                                                            } w-full px-12 py-2 text-sm flex flex-col`}
                                                            href={
                                                                "/community/" +
                                                                community.nameId
                                                            }
                                                        >
                                                            <span>{community.name}</span>

                                                            <span className="text-gray-600">
                                                                {community.contact[0]
                                                                    .address[0]
                                                                    ? community.contact[0]
                                                                          .address[0].city
                                                                    : ""}
                                                                ,{" "}
                                                                {community.contact[0]
                                                                    .address[0]
                                                                    ? community.contact[0]
                                                                          .address[0].state
                                                                    : ""}
                                                            </span>
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                    </div>
                                ) : null}
                            </Menu.Items>
                        </MenuPortal>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default HomeSearch;
